import Vue from 'vue'
import Vuex from 'vuex'
import { tradeShoppingCartList } from '@/http/shoppingCar'
import { tradeTimeQueryTradeTimeRule, tradeTimeQueryTradeTimeState } from '@/http/common/'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    mallFrontDeskToken: null,
    carNum: 0, // 改变首页的购物车数量
    userCmpRole: sessionStorage.userCmpRole || '',
    dictionaryEntry: null, // 字典所有数据
    mallUserInfo: JSON.parse(sessionStorage.getItem(`mallFrontDeskUserInfo${sessionStorage.userCmpRole}`)) || {},
    orderPayStyle: sessionStorage.orderData ? JSON.parse(sessionStorage.orderData).payStyle : '',
    tradeTimeRule: {},
    tradeTimeState: null
  },
  mutations: {
    // 获取所有字典项
    getDictionaryEntry(state, dictionaryEntry) {
      state.dictionaryEntry = dictionaryEntry
      localStorage.setItem('dictionaryEntry', JSON.stringify(dictionaryEntry))
    },
    // 设置所属的角色（买家or卖家）
    setRoles(state, userCmpRole) {
      state.userCmpRole = userCmpRole
      sessionStorage.setItem('userCmpRole', userCmpRole)
    },
    getToken(state, mallFrontDeskToken) {
      state.mallFrontDeskToken = mallFrontDeskToken
      localStorage.setItem(`mallFrontDeskToken${state.userCmpRole}`, mallFrontDeskToken)
    },
    // 改变购物车数量的
    changeCarNum(state, carNum) {
      state.carNum = carNum
    },
    getUserInfo(state, mallUserInfo) {
      state.mallUserInfo = mallUserInfo
      localStorage.setItem(`mallFrontDeskUserInfo${state.userCmpRole}`, JSON.stringify(mallUserInfo))
    },
    getOrderPayStyle(state, orderPayStyle) {
      state.orderPayStyle = orderPayStyle
    },
    // 交易时间规则
    changeTradeTimeRule(state, tradeTimeRule) {
      state.tradeTimeRule = tradeTimeRule
    },
    // 交易时间是否在营业时间范围内
    changeTradeTimeState(state, tradeTimeState) {
      state.tradeTimeState = tradeTimeState
    },
    changeLoading(state, type) {
      state.loading = type
    }
  },
  actions: {
    getCarNum(context) {
      tradeShoppingCartList(res => {
        let num = 0
        if (res.data) {
          res.data.forEach((item) => {
            if (item.respTradeShoppingCartList) num += item.respTradeShoppingCartList.length
          })
        }
        context.commit('changeCarNum', num)
      })
    },
    // 交易时间规则
    getTradeTime(context) {
      tradeTimeQueryTradeTimeRule(res => {
        if (res.data) {
          context.commit('changeTradeTimeRule', res.data)
        }
      })
    },
    // 交易时间是否在营业时间范围内
    getTradeTimeState(context) {
      tradeTimeQueryTradeTimeState(res => {
        if (res.data) {
          context.commit('changeTradeTimeState', res.data)
        }
      })
    }
  },
  getters: {
    // 获取字典单个项的数据
    getDictionaryItem: (state) => (item) => {
      const dictionaryEntry = state.dictionaryEntry || JSON.parse(localStorage.getItem('dictionaryEntry'))
      return dictionaryEntry[item]
    }
  },
  modules: {}
})
