
const SRCURL = `https://api.369tecgroup.com/scm/v1`
const BSERURL = `https://api.369tecgroup.com/plat/approveabc/`
const SCFURL = `https://api.369tecgroup.com/scf/approveabc/`
const CMSSRCUL = `https://api.369tecgroup.com/cms/approveabc`
const APISRCUL = `https://api.369tecgroup.com/scm/api/v1`
const projectUrl = `https://b2b.369tecgroup.com/scm/#/`
const scfPlatformUrl = `https://api.369tecgroup.com/scf/#/`
const agentUrl = `https://agent.369tecgroup.com/#/login`
const IMGPATH = `https://image.369tecgroup.com/images/`
const SCMAPI = `https://api.369tecgroup.com/scm/api/`
const ICFSAPI = `https://icf.369tecgroup.com/supermarket/v1/`
export {
  BSERURL,
  IMGPATH,
  SRCURL,
  APISRCUL,
  CMSSRCUL,
  SCFURL,
  projectUrl,
  scfPlatformUrl,
  agentUrl,
  ICFSAPI,
  SCMAPI
}
