<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getSelectAllDict } from '@/http/common'
import Vue from 'vue'
export default {
  async mounted() {
    // 获取字典所有数据呀
    const res = await getSelectAllDict
    const dictionaryEntry = JSON.parse(localStorage.getItem('dictionaryEntry'))
    this.$store.commit('getDictionaryEntry', res.data || dictionaryEntry)
    if (res.data['documentLeadingEdge'] || dictionaryEntry['documentLeadingEdge'])Vue.prototype.$fileUrl = this.$store.getters.getDictionaryItem('documentLeadingEdge')[0].dictName || dictionaryEntry['documentLeadingEdge'][0].dictName
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
