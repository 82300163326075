// 根据dictId获取data中对应的dictName
export const getDictNameByData = (data, dictId) => {
  // console.log('data,dictId', data,dictId)
  if (!data) {
    return
  }
  if (!dictId && dictId !== 0) return '-'
  const stat = data.every(item => {
    return item['dictId'] !== dictId
  })
  if (stat) {
    return '-'
  } else {
    return data.filter(item => {
      return item['dictId'] === dictId
    })[0]['dictName']
  }
}
